import React from "react"
import Img from "gatsby-image"
import { Section } from "../CompoundComponents/Section"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { Columns, Column } from "../CompoundComponents/Columns"
import Button from "../Button"
import Icon from "../Icon"

const Footer = ({ intl, data, order, light }) => (
  <Section medium noPaddingBottom white>
    <Columns vcentered centered rowReverse>
      <Column is6 noPaddingBottom>
        <Img fluid={data.node.featuredImage.childImageSharp.fluid} />
      </Column>

      <Column is5 offset1>
        <h2 className="is-size-4 has-text-centered-mobile is-size-5-mobile has-text-weight-bold mb-1">
          {intl.formatMessage({ id: "index.footer.title" })}
        </h2>
        <div className="has-text-centered-mobile mb-1">
          {intl.formatMessage({ id: "index.footer.desc" })}
        </div>
        <div className="has-text-centered-mobile">
          <Button
            ripple
            aTag
            primary
            mr
            bottom
            link="https://play.google.com/store/apps/details?id=uz.nihol_sihatgoh.www.twa&hl=ru&gl=US"
          >
            <Icon icon={["fab", "google-play"]} white />
            <span className="has-text-weight-bold">Nihol Sihatgoh</span>
          </Button>

          <p className="centered buttons">
            <Button aTag mr link="https://www.facebook.com">
              <Icon icon={["fab", "facebook"]} white />
              <span className="has-text-weight-bold">Facebook</span>
            </Button>
            <Button aTag mr link="https://www.instagram.com">
              <Icon icon={["fab", "instagram"]} white />
              <span className="has-text-weight-bold">Instagram</span>
            </Button>
          </p>
          <p className="is-size-7">
            © {new Date().getFullYear()} Нихол Cаломатлик Cихатгохи.{" "}
            {intl.formatMessage({ id: "footer.copyright" })}{" "}
            <Link
              to="/privacy"
              className="is-size-7 has-text-primary"
              activeClassName="is-active"
            >
              {intl.formatMessage({ id: "footer.privacy.title" })}
            </Link>
            <br />
            Сайт яратилган{" "}
            <a
              href="https://jahondev.uz/"
              target="_blank"
              className="has-text-link"
            >
              www.jahondev.uz
            </a>
          </p>
        </div>
      </Column>
    </Columns>
  </Section>
)

export default injectIntl(Footer)
