import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"

const Icon = ({ icon, blink, hoverIcon, size, large }) => (
  <span
    className={classNames("icon", {
      Blink: blink,
      "hvr-icon": hoverIcon,
      "is-large": large,
    })}
  >
    <FontAwesomeIcon icon={icon} size={size} />
  </span>
)

export default Icon
