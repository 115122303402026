import React from "react"
import { Link } from "react-scroll"
import { injectIntl } from "gatsby-plugin-intl"
import classNames from "classnames"

const Button = ({
  children,
  link,
  warning,
  black,
  nav,
  success,
  ripple,
  mr,
  location,
  aTag,
  bottom,
  noBlank,
  primary,
}) => (
  <>
    {aTag ? (
      <a
        href={link}
        target={!noBlank && "_blank"}
        className={classNames(
          "button has-text-weight-bold is-normal is-size-7-mobile is-rounded",
          {
            "mb-1": bottom,
            "is-warning": warning,
            "is-primary": primary,
            "is-black": black,
            "is-success": success,
            "navbar-item": nav,
            "hvr-ripple-out": ripple,
            "mr-05": mr,
          }
        )}
      >
        {children}
      </a>
    ) : (
      <div
        className={classNames(
          "button has-text-weight-bold is-normal is-size-7-mobile is-uppercase is-rounded",
          {
            "mb-1": bottom,
            "is-warning": warning,
            "is-primary": primary,
            "is-black": black,
            "is-success": success,
            "navbar-item": nav,
            "hvr-ripple-out": ripple,
            "mr-05": mr,
          }
        )}
      >
        {children}
      </div>
    )}
  </>
)

export default injectIntl(Button)
